import './src/styles/global.css';
import './src/styles/header.css';
import './src/styles/contact.css';
import './src/styles/research.css';
import './src/styles/waveForm.css';
import React from 'react';

import { RecoilRoot } from 'recoil';

export const wrapRootElement = ({ element }) => {
  return <RecoilRoot>{element}</RecoilRoot>;
};
